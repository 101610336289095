import {useEffect} from 'react';
import Home from './features/home/Home';
import Login from './features/login/Login';
import logo from './logo.png';
import {
    clearTokens,
    selectLoaded, selectUserPolicies,
    setLoaded,
    setTokens,
} from "./features/auth/slice";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {apiAxios as axios} from './features/api/axios';
import Sidebar from "./features/sidebar/Sidebar";
import {Index as VehiclesIndex} from "./features/vehicles/Index";
import {Index as RoutesIndex} from "./features/routes/Index";
import {Index as PassengerIndex} from "./features/passengers/Index"
import {Index as UploadIndex} from "./features/bulk_upload/Index"
import {DeleteDialog as CardDeleteDialog} from "./features/cards/DeleteDialog";
import {DeleteDialog as PassengerDeleteDialog} from "./features/passengers/DeleteDialog";
import {DeleteDialog as DeviceDeleteDialog} from "./features/devices/DeleteDialog";
import {Index as DeviceIndex} from "./features/devices/Index"
import {EditModal as EditReservationModal} from "./features/devices/EditModal";
import {EditModal as EditDeviceModal} from "./features/devices/EditModal";
import {UiNotification} from "./features/notification/UiNotification";
import VehicleEditModal from './features/vehicles/EditModal';
import VehicleDeleteDialog from './features/vehicles/DeleteDialog';
import RouteCreateModal from './features/routes/CreateModal';
import {Edit as RoutesEdit} from './features/routes/Edit';
import {refreshAction} from './features/auth/actions';
import {loadRefreshToken, refresh, saveAuth} from './features/auth/service';
import AppRoute from './features/routing/AppRoute';
import PrivateRoute from './features/routing/PrivateRoute';
import {Index as DriversIndex} from './features/drivers/Index';
import {Index as CovidIndex} from './features/covid/Index';
import {Index as SessionIndex} from './features/sessions/Index';
import {Edit as DriversEdit} from './features/drivers/Edit';
import {AddPhoto as PassengersAddPhoto} from "./features/passengers/AddPhoto";
import {Edit as DiscountEdit} from './features/discounts/Edit';
import {Index as UnauthorisedIndex} from './features/unauthorised/Index';
import {Index as DiscountIndex} from './features/discounts/Index';
import {Index as TopBar} from './features/topbar/Index';
import {Edit as ReaderPromosEdit} from './features/readerPromos/Edit';
import {Index as ReaderPromosIndex} from './features/readerPromos/Index';
import {Edit as AppPromosEdit} from './features/appPromos/Edit';
import {Index as ReportsIndex} from './features/reports/Index';
import {Index as AppPromosIndex} from './features/appPromos/Index';
import {Edit as AppNotificationEdit} from './features/appNotifications/Edit';
import {Index as AppNotificationIndex} from './features/appNotifications/Index';
import {Edit as OrganisationsEdit} from './features/organisations/Edit';
import {Index as OrganisationsIndex} from './features/organisations/Index';
import {Index as UserLogsIndex} from './features/userLogs/Index';
import {LiveModal as LiveSessionModal} from "./features/sessions/LiveModal";
import {Index as UserIndex} from './features/users/Index';
import {Edit as UserEdit} from './features/users/Edit';
import Dashboard from "./features/dashboard/Dashboard";
import ForgotPassword from './features/password/ForgotPassword';
import ResetPassword from './features/password/ResetPassword';
import {EditAdd as PassengersEditAdd} from "./features/passengers/EditAdd";
import {AddTest} from "./features/covid/AddTest";
import {ExposedPeopleReport} from "./features/covid/ExposedPeopleReport";
import {getPermissionsLevel, Permission} from "./features/policies/helpers";
import {Add} from "./features/permissions/Add";
import {Index as CarersIndex} from "./features/carers/Index";
import {Edit as CarersEdit} from "./features/carers/Edit";
import {Index as MessageIndex} from "./features/message/Index";
import {View as RouteView} from "./features/routes/View";
import {View as DriverView} from "./features/drivers/View";
import {Register} from "./features/passengers/Register";
import {Complete} from "./features/registration/Complete";
import {Index as ExportIndex} from "./features/export/Index";

function App() {
    const dispatch = useAppDispatch();
    const authLoaded = useAppSelector(selectLoaded);
    const policies = useAppSelector(selectUserPolicies);

    const routePermissionLevel = getPermissionsLevel('route', policies);
    const stopPermissionLevel = getPermissionsLevel('stop', policies);
    const passengerPermissionLevel = getPermissionsLevel('passenger', policies);
    const driverPermissionLevel = getPermissionsLevel('driver', policies);
    const covidPermissionLevel = getPermissionsLevel('covid', policies);
    const appNotificationPermissionLevel = getPermissionsLevel('appNotification', policies);
    const organisationPermissionLevel = getPermissionsLevel('organisation', policies, undefined, true);
    const appPromoPermissionLevel = getPermissionsLevel('appPromo', policies);
    const carerPermissionLevel = getPermissionsLevel('carer', policies);
    const discountPermissionLevel = getPermissionsLevel('discount', policies);
    const readerPromoPermissionLevel = getPermissionsLevel('readerPromo', policies);
    const sessionMaxPermissionLevel = getPermissionsLevel('session', policies, undefined, true);
    const routeMaxPermissionLevel = getPermissionsLevel('route', policies, undefined, true);
    const passengerMaxPermissionLevel = getPermissionsLevel('passenger', policies, undefined, true);
    const carerMaxPermissionLevel = getPermissionsLevel('carer', policies, undefined, true);
    const driverMaxPermissionLevel = getPermissionsLevel('driver', policies, undefined, true);
    const vehicleMaxPermissionLevel = getPermissionsLevel('vehicle', policies, undefined, true);
    const boxMaxPermissionLevel = getPermissionsLevel('box', policies, undefined, true);
    const covidMaxPermissionLevel = getPermissionsLevel('covid', policies, undefined, true);
    const appNotificationMaxPermissionLevel = getPermissionsLevel('appNotification', policies, undefined, true);
    const organisationMaxPermissionLevel = getPermissionsLevel('organisation', policies, undefined, true);
    const appPromoMaxPermissionLevel = getPermissionsLevel('appPromo', policies, undefined, true);
    const userMaxPermissionLevel = getPermissionsLevel('user', policies, undefined, true);
    const discountMaxPermissionLevel = getPermissionsLevel('discount', policies, undefined, true);
    const readerPromoMaxPermissionLevel = getPermissionsLevel('readerPromo', policies, undefined, true);
    const emailMaxPermissionLevel = getPermissionsLevel('email', policies, undefined, true);


    useEffect(() => {
        const savedRefreshToken = loadRefreshToken();
        if (savedRefreshToken) {
            dispatch(refreshAction({refreshToken: savedRefreshToken}))
        } else {
            dispatch(setLoaded(true));
        }
    }, []);


    let promise: any = null;

    axios.interceptors.response.use((response) => {
        return response;
    }, async (err) => {
        if (err.response && err.response.status === 403) {
            window.location.replace(`${process.env.REACT_APP_BASE_HREF}/unauthorised`);
            return Promise.reject(err)
        } else if (err.response && err.response.status !== 401) {
            return Promise.reject(err);
        }

        const refreshPromise = async () => {

            const token = loadRefreshToken()

            if (!promise) {
                promise = refresh(token).then(newTokensResponse => {
                    const newTokens = newTokensResponse.data;
                    dispatch(setTokens(newTokens));
                    saveAuth(newTokens.access_token, newTokens.refresh_token, newTokens.expires_in);
                    promise = null;
                    return newTokensResponse;
                });

            }
            return promise;
        };

        const originalReq = err.config;
        let refresh_token = loadRefreshToken();

        if (err.response && err.response.status === 401 && err.config.url && err.config.url.includes('/token')) {
            dispatch(clearTokens())
            window.location.reload()
        } else if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest && refresh_token && !err.config.url.includes('/token')) {
            return await refreshPromise().then(function (res) {
                originalReq.headers['Authorization'] = 'Bearer ' + res.data.access_token;
                return axios(originalReq);

            });
        }
        return Promise.reject(err);
    });


    if (!authLoaded) {
        return (<div className="flex items-center justify-center w-screen h-screen">
            <div className="text-center">
                <img src={logo} alt="Zywpeit Logo" className="h-10 m-auto mb-4"/>
                <span className="text-gray-400">Loading...</span>
            </div>
        </div>);
    }
	
	const domain = window.location.host;
	
	
	
	if(domain=="app.zywpeit.com")
	{
       console.log(domain + " new front end");
	   
	       return (
        <div className="flex App">
            <Router>
                <Sidebar/>
                <div className="w-10/12">
                    <TopBar/>
                    <div className="p-4">
                        <UiNotification/>
                        <Switch>
						    
						
                            <AppRoute path="/login" title="Login">
                                <Login/>
                            </AppRoute>
                            <AppRoute path="/forgotPassword" title="Forgot Password">
                                <ForgotPassword/>
                            </AppRoute>
                            <AppRoute path="/register/:id" title="Register">
                                <Register/>
                            </AppRoute>
                            <AppRoute path="/registration-complete" title="Completed Registration">
                                <Complete/>
                            </AppRoute>
                            <AppRoute path="/resetPassword" title="Reset Password">
                                <ResetPassword/>
                            </AppRoute>
                            <PrivateRoute path="/unauthorised" title="Unauthorised" permission={true}>
                                <UnauthorisedIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/" title="Dashboard" permission={true}>
                                <Dashboard/>
                            </PrivateRoute>
                            <PrivateRoute path="/vehicles" title="Vehicles"
                                          permission={vehicleMaxPermissionLevel >= Permission.Read}>
                                <VehiclesIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/passengers" title="Passengers"
                                          permission={passengerMaxPermissionLevel >= Permission.Read}>
                                <PassengerIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/passengers/new" title="New Passenger"
                                          permission={passengerPermissionLevel >= Permission.Create}>
                                <PassengersEditAdd/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/passengers/:id/photo" title="New Passenger"
                                          permission={passengerMaxPermissionLevel >= Permission.Read}>
                                <PassengersAddPhoto/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/passengers/:id/edit" title="Edit Passenger"
                                          permission={passengerMaxPermissionLevel >= Permission.Read}>
                                <PassengersEditAdd/>
                            </PrivateRoute>
                            <PrivateRoute exact path={"/guardians"} title="Guardians"
                                          permission={carerMaxPermissionLevel >= Permission.Read}>
                                <CarersIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path={"/guardians/new"}  title="New Guardian"
                                          permission={carerPermissionLevel >= Permission.Create}>
                                <CarersEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/guardians/:id/edit" title="Edit Guardian"
                                          permission={carerMaxPermissionLevel >= Permission.Read}>
                                <CarersEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/routes" title="Routes"
                                          permission={routeMaxPermissionLevel >= Permission.Read}>
                                <RoutesIndex/>
                            </PrivateRoute>
                            <PrivateRoute path="/routes/:id/edit" title="Edit Route"
                                          permission={routeMaxPermissionLevel >= Permission.Edit}>
                                <RoutesEdit/>
                            </PrivateRoute>
                            <PrivateRoute path="/routes/:id/view" title="View Route"
                                          permission={routeMaxPermissionLevel >= Permission.Read}>
                                <RouteView/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/drivers" title="Drivers"
                                          permission={driverMaxPermissionLevel >= Permission.Read}>
                                <DriversIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/drivers/new" title="New Driver"
                                          permission={driverPermissionLevel >= Permission.Create}>
                                <DriversEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/drivers/:id/edit" title="Edit Driver"
                                          permission={driverMaxPermissionLevel >= Permission.Edit}>
                                <DriversEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/drivers/:id/view" title="View Driver"
                                          permission={driverMaxPermissionLevel >= Permission.Read}>
                                <DriverView/>
                            </PrivateRoute>
                            <PrivateRoute path="/devices" title="Devices"
                                          permission={boxMaxPermissionLevel >= Permission.Read}>
                                <DeviceIndex/>
                            </PrivateRoute>
                            <PrivateRoute path="/sessions" title="Live Routes"
                                          permission={sessionMaxPermissionLevel >= Permission.Read}>
                                <SessionIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/reports" title="Reports"
                                          permission={sessionMaxPermissionLevel >= Permission.Read}>
                                <ReportsIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/discounts" title="Discounts"
                                          permission={discountMaxPermissionLevel >= Permission.Read}>
                                <DiscountIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/discounts/new" title="New Discount"
                                          permission={discountPermissionLevel >= Permission.Create}>
                                <DiscountEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/discounts/:id/edit" title="Edit Discount"
                                          permission={discountMaxPermissionLevel >= Permission.Edit}>
                                <DiscountEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/readerPromos" title="Reader Promotions"
                                          permission={readerPromoMaxPermissionLevel >= Permission.Read}>
                                <ReaderPromosIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/readerPromos/new" title="New Reader Promotion"
                                          permission={readerPromoPermissionLevel >= Permission.Create}>
                                <ReaderPromosEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/messages" title="Help Requests"
                                          permission={emailMaxPermissionLevel >= Permission.Create}>
                                <MessageIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/readerPromos/:id/edit" title="Edit Reader Promotion"
                                          permission={readerPromoMaxPermissionLevel >= Permission.Edit}>
                                <ReaderPromosEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/appPromos" title="App Promotions"
                                          permission={appPromoMaxPermissionLevel >= Permission.Read}>
                                <AppPromosIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/appPromos/new" title="New App Promotion"
                                          permission={appPromoPermissionLevel >= Permission.Create}>
                                <AppPromosEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/appPromos/:id/edit" title="Edit App Promotion"
                                          permission={appPromoMaxPermissionLevel >= Permission.Edit}>
                                <AppPromosEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/organisations" title="Organisations"
                                          permission={organisationMaxPermissionLevel >= Permission.Edit}>
                                <OrganisationsIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/organisations/new" title="New Organisation"
                                          permission={organisationPermissionLevel >= Permission.Create}>
                                <OrganisationsEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path={["/permissions/add", "/permissions/add/:id"]} title="Add Permissions"
                                          permission={organisationPermissionLevel >= Permission.Delete}>
                                <Add/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/permissions/:id" title="Permissions"
                                          permission={organisationPermissionLevel >= Permission.Delete}>
                                <Add/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/organisations/:id/edit" title="Edit Organisation"
                                          permission={organisationMaxPermissionLevel >= Permission.Edit}>
                                <OrganisationsEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/appNotifications" title="App Notification"
                                          permission={appNotificationMaxPermissionLevel >= Permission.Read}>
                                <AppNotificationIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/appNotifications/new" title="New App Notification"
                                          permission={appNotificationPermissionLevel >= Permission.Create}>
                                <AppNotificationEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/appNotifications/:id/edit" title="Edit App Notification"
                                          permission={appNotificationMaxPermissionLevel >= Permission.Edit}>
                                <AppNotificationEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/covid/exposed/:id" title="Exposed Sessions"
                                          permission={covidMaxPermissionLevel >= Permission.Read}>
                                <ExposedPeopleReport/>
                            </PrivateRoute>
                            <PrivateRoute exact path={["/covid/:id", "/covid"]} title="Covid Tests Info"
                                          permission={covidMaxPermissionLevel >= Permission.Read}>
                                <CovidIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path={["/covid/new/:id", "/covid/add/new"]} title="New Covid Test"
                                          permission={covidPermissionLevel >= Permission.Create}>
                                <AddTest/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/userLogs" title="User Logs"
                                          permission={(userMaxPermissionLevel >= Permission.Read || passengerMaxPermissionLevel >= Permission.Read || driverMaxPermissionLevel >= Permission.Read)}>
                                <UserLogsIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/users" title="Users"
                                          permission={(userMaxPermissionLevel >= Permission.Delete)}>
                                <UserIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path={["/users/new", "/users/new/:organisationId"]} title="New User"
                                          permission={(userMaxPermissionLevel >= Permission.Create || passengerMaxPermissionLevel >= Permission.Create || driverMaxPermissionLevel >= Permission.Create)}>
                                <UserEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/users/:id/edit" title="Edit User"
                                          permission={(userMaxPermissionLevel >= Permission.Edit || passengerMaxPermissionLevel >= Permission.Edit || driverMaxPermissionLevel >= Permission.Edit)}>
                                <UserEdit/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/upload" title="Upload"
                                          permission={(carerPermissionLevel >= Permission.Edit || passengerPermissionLevel >= Permission.Edit || routePermissionLevel >= Permission.Edit || stopPermissionLevel >= Permission.Edit)}>
                                <UploadIndex/>
                            </PrivateRoute>
                            <PrivateRoute exact path="/export" title="Reporting Exports"
                                          permission={(organisationPermissionLevel >= Permission.Edit)}>
                                <ExportIndex/>
                            </PrivateRoute>
                        </Switch>
                        <VehicleEditModal/>
                        <EditDeviceModal/>
                        <EditReservationModal/>
                        <RouteCreateModal/>
                        <LiveSessionModal/>
                        <DeviceDeleteDialog/>
                        <PassengerDeleteDialog/>
                        <CardDeleteDialog/>
                        <VehicleDeleteDialog/>
                    </div>
                </div>
            </Router>
        </div>
    );
	
	}
	else
	{
	  console.log(domain + " main website");
	      return (
        <div className="flex Appss">
            <Router>
                <Sidebar/>
                <div className="w-10/12ss">
                    <TopBar/>
                    <div className="p-4ss">
                        <UiNotification/>
                        <Switch>
						    
						
                           <AppRoute path="/" title="Home">
                                <Home/>
                            </AppRoute>
                            
                        </Switch>
                       
                    </div>
                </div>
            </Router>
        </div>
    );
	
	}
	   
  
}

export default App;
