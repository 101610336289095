import { WebView } from 'react-native-webview';
import React, { Component } from 'react';
import './home.css';


function Home() {


   return (
        <div id="ifndiv" >
            {
                 <iframe id="ifn" src="front-end-site/index.html"
                         width="100%" 
                height="100%" />
            }
        </div>
);
   
}

export default Home;